import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { WebapiService } from './webapi.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})


export class AppComponent {
  role = [];
  id="1";
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public service: WebapiService,
    public router:Router
    ) {
      this.id=localStorage.getItem('user')

    this.initializeApp();
    this.id=localStorage.getItem('user')

    

  }
  getdatau:any=[]
  resultr:any;
  get_user() {
    this.service.postData({iid:this.id}, 'get_roles').then((result) => {

      console.log(result);
      var temp: any = result;
      this.resultr = temp
      this.role=JSON.parse(temp.record[0].otherDetails1);

      this.service.useroles=this.role;
      console.log(this.role)
    })
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      
    });
    
    this.router.events.subscribe((e:any) => {
      if (e instanceof NavigationStart || e instanceof NavigationEnd) {
        console.log(e.url);

        if((e.url!='/') &&(e.url!='/signup') && (e.url.startsWith('/login')==false)  && (e.url.startsWith('/setpassword')==false))
          this.addaccesslog('Partnerapp/'+e.url)
        // var role=localStorage.getItem('role');
        // // var role='Demand sensing'
        // if(role=='Demand sensing' && ((e.url!='/demandsensing')&& (e.url!='/userprofile')&& (e.url!='/info')))
        //   this.router.navigateByUrl('/demandsensingnew')

      }
    });
  }

  addaccesslog(module){
    // iUserid =_json['iUserid']
    // sUseremail =_json['sUseremail']
    // dtStarttime =_json['dtStarttime']
    // dtEndtime =_json['dtEndtime']
    // iModule =_json['iModule']
    var userid =localStorage.getItem('user');
    var email =localStorage.getItem('email');
    var filter ={'iUserid':userid,'sUseremail':email,'iModule':module}
    // else
    //   filter ={'startdate':this.endofweek.add(1,'days').format('MM/DD/YYYY'),nweek:4,weeklist:[]}

      
    this.service.postData(filter, 'accesslog').then((result) => {

      console.log('access',result);

    });
  }
}
